import {
  IS_INITIALIZED,
  IS_LOADING,
  DATA_POINTS,
  FILTERS,
  IS_FETCHABLE
} from "./getter-types";

export default {
  [IS_INITIALIZED]: state => state.isInitialized,
  [IS_LOADING]: state => state.isLoading,
  [DATA_POINTS]: state => state.dataPoints,
  [FILTERS]: state => state.filters,
  [IS_FETCHABLE]: state => state.fetchable
};
